import React, { useState } from 'react';
import './home.css';

const Home = () => {
 // State to hold the value of the textarea
 const [text, setText] = useState('');

 // Function to handle changes in the textarea
 const handleChange = (event) => {
   setText(event.target.value);
 };

 // Function to handle form submission
 const handleSubmit = (event) => {
   event.preventDefault();
   // Handle submission logic here
   console.log('Submitted:', text);
   // Clear the textarea after submission
   setText('');
 };
  return (
    <div className="flex-grid-container">
    <div className="flex-grid-item-50a">
        <div className='leftHolder'>
            <img src='LOGO.png' width="150"/>
            <div className='homeHeader'>Taste <br/>Happiness,<br/> Live Vibrantly.</div>
            <p className='homeText'>Discover guilt-free indulgences, wholesome recipes and vibrant living with VYBE. Get exclusive early access to our launch offers.</p>
            <form onSubmit={handleSubmit} className="formContainer">
            <textarea
                value={text}
                onChange={handleChange}
                placeholder="enter your email address..."
                className="textArea"
            />
            <button type="submit" className="submitButton">GET ACCESS</button>
            </form>
            <p className='socialText'>FOLLOW US ON SOCIAL MEDIA FOR SNEAK PEEKS!</p>
            <div className="social-icons">
            <a href="#"><img src="IG.png" alt="Instagram" className="social-icon" /></a>
            <a href="#"><img src="ic_baseline-tiktok.png" alt="TikTok" className="social-icon" /></a>
            <a href="#"><img src="mdi_youtube.png" alt="Youtube" className="social-icon" /></a>
            <a href="#"><img src="ic_baseline-facebook.png" alt="Facebook" className="social-icon" /></a>
            </div>
        </div>
    </div>
    <div className="flex-grid-item-50-a">
        <img src="Vybe-Cookies.png" alt="Vybe Cookies" className="grid-image" />
    </div>
    <div className="flex-grid-item-100">
        <img src='VybeTitle.png' className='imageOverlay'/>
    </div>
    <div className="flex-grid-item-25-a">
        <p className='subTitle'>VIBRANT</p>
        <p>Reflecting the lively and soulful energy that comes from the source of our products to the passion of our team.</p>
        <img src="tabler_north-star.png" width="50" height='50' />
    </div>
    <div className="flex-grid-item-25-b">
        <p className='subTitle'>WHY</p>
        <p>Growing from sustainability and innovation, we aim to spark and nurture meaningful change through heartfelt offerings centered around a plantbased approach.</p>
        <img src="why.png" width="40" height='40'/>
    </div>
    <div className="flex-grid-item-25-c">
    <p className='subTitle'>BALANCED</p>
        <p>Demonstrating our dedication to balanced nutrition and holistic well-being.</p>
        <img src="mage_health-circle.png" width="50" height='50'/>
    </div>
    <div className="flex-grid-item-25-d">
    <p className='subTitle'>EMPOWERING</p>
        <p>Signifying our mission to empower individuals to make healthier choices and live a vibrant life.</p>
        <img src="heart.png" width="40" height='40'/>
    </div>
    <div className="flex-grid-item-50b">
        <div className='footerHolder'>
            <div className='footerTitle'>CONTACT US</div>
            <div className='footerTextEmail'>hello@vybe.eco</div>
            <div className='footerTitle'>©2024 VYBE.eco. All rights Reserved.</div>
        </div>
    </div>
    <div className="flex-grid-item-50b">
        <div className='footerHolder'>
            <div className='footerTitle'>PRIVACY STATEMENT</div>
            <div className='footerText'>We respect your privacy. By signing up for updates, you agree to receive emails about our upcoming launch and special offers. We will never share your email address with third parties without your consent. You can unsubscribe from our emails at any time by clicking the link at the bottom of any email we send you.</div>
        </div>
    </div>
  </div>
  );
};

export default Home;
